@import "./style/theme.scss";

body {
  font-family: Arial, sans-serif;
  font-size: 15px;
  margin: 0;
  color: $themeColor;

  .artwork--container {
    position: absolute;
    z-index: -1;
    background-image: url(./static/kystlinjen.svg);
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-bottom: 120px !important;
    min-height: calc(100vh - 78px - 120px);
  }

  h1 {
    font-size: 35px;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 12px;
  }

  big {
    font-weight: 500;
  }

  .icon {
    @include square(16px);
  }
}

a:not(.btn),
a:visited:not(.btn) {
  color: $themeColor;
  text-decoration: underline;
}
a:hover:not(.btn) {
  color: lighten($themeColor, 10%);
}

.rotating {
  @include rotate();
}
