@import "../style/theme.scss";

.zoom-to-extent-btn {
  position: fixed;
  top: 220px;
  left: 10px;
  margin: 1px;
  @include mapButton;

  .icon {
    height: 16px;
    width: 16px;
    margin: 0 auto;

    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }
}