   @import './style/theme.scss';

// -----------------------------
// Map buttons
// -----------------------------

.layer-switch--button-container {
  position: fixed;
  top: 176px;
  left: 10px;
  background: none;
  padding: 0;

  &.fullscreen--on {
    right: 10px;
  }

  .layer-switch--button {
    @include mapButton;
    display: inline-block;

    svg {
        width: 16px;
        height: 16px;
      path {
        fill: #fff;
      }
    }

    &:hover {
      background: $mapButtonHoverBackground;
    }
  }
}

  .zoom-control--root {
    position: fixed;
    top: 88px;
    left: 10px;
    background: none;
    border-radius: 0;
    padding: 0;

    .zoom-in {
      margin-bottom: 4px;
    }

    .zoom-in,
    .zoom-out {
      @include mapButton;
    }
  }

  .ol-scale-line {
      background: $overlay;
  }

// -----------------------------
// Map layer switch container
// -----------------------------

.layer-switch--container {
  position: fixed;
  border-radius: 2px;
  top: 176px;
  left: 60px;
  background: $overlay;

  .layer-group--list-item {
    .icon {
      height: 16px;
      width: 16px;
      vertical-align: middle;

      svg {
        fill: $themeColor;
      }
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}