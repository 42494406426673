@import "../style/theme.scss";

.popup-box--overlay {
  @include fixed(0, 0, 0, 0);
  z-index: 10000;
  background: rgba(0, 0, 0, 0.6);

  .popup-box--container {
    @include fixed($top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
    width: 640px;
    background: $white-base;
    z-index: 1000;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      left: 20px;
      right: 20px;
      width: auto;
      transform: translateY(-50%);
    }

    .popup-box--content {
      overflow-y: auto;
      padding: 20px;
      max-height: calc(80vh - 100px);
    }

    .popup-box--btn-container {
      padding: 10px 20px;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;

      .popup-box--checkbox-container {
        flex: 1 1 150px;
        height: 50px;
        padding-top: 15px;

        .checkbox--container {
          margin-right: 10px;
        }

        label {
          font-size: 12px;
        }
      }

      .popup-box--close-btn {
        border: none;
        flex: 1 1 200px;
        height: 50px;
        max-width: 200px;
        background: $themeColor;
        color: $white-base;
        right: 20px;
        text-transform: uppercase;
      }
    }
  }
}
