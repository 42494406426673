@import "../style/global.scss";

.nav-bar--container {
  width: 100%;
  height: 68px;
  @include fixed(0);
  z-index: 10001;
  background: $themeColor;
  padding: 5px;

  & > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .nav-bar--header-image {
    img {
      height: 100px;
      margin-top: -15px;
    }
  }
  .cms--nav a:not(.btn) {
    position: relative;
    color: $color-text-light;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    padding: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-bar--hamburger {
    display: none;
  }

  .nav-bar--menu {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    text-align: right;
  }
  .nav-bar--langbtns {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    text-align: right;
    button {
      background: $themeColor;
      border: 1px solid #fff;
      color: #fff;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    .nav-bar--menu {
      margin-right: 10px;
      text-align: left;
    }
    .cms--nav {
      @include absolute($top: 46px, $right: -10px);
      @include size(50vw, 0);
      display: block;
      background: $themeColor;
      overflow: auto;
      opacity: 0;
      transition: opacity 200ms;
      pointer-events: none;
      box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.1);
      padding: 10px 0;
      a:not(.btn) {
        display: block;
        padding: 10px 25px;

        &:after {
          display: none;
        }

        border-left: 2px solid $themeColor;
      }
    }

    .nav-bar--hamburger {
      display: block;
      @include square(48px);
      margin: 0 0 -46px;
      opacity: 0;
      cursor: pointer;
      z-index: 2;

      & ~ span {
        @include size(20px, 2px);
        display: block;
        border-radius: 2px;
        background: $color-text-light;
        margin: 4px auto;
        transition: transform ease-in-out 200ms, opacity ease-in-out 100ms;
        opacity: 1;
        pointer-events: none;
      }

      &:checked ~ span:nth-child(2) {
        transform: translateY(6px) translateX(0) rotate(-45deg);
      }

      &:checked ~ span:nth-child(4) {
        transform: translateY(-6px) translateX(0) rotate(45deg);
      }

      &:checked ~ span:nth-child(3) {
        opacity: 0;
      }
    }

    .nav-bar--hamburger:checked ~ .cms--nav {
      pointer-events: all;
      height: auto;
      opacity: 1;
    }
  }
}
