@import "../style/theme.scss";

.page-layout--root {
  margin-top: 78px;
  width: 100%;
  .page-layout--components {
    background: #fff;
    width: 80%;
    margin: 0 auto; 
    min-height: calc(80vh - 78px);
    box-shadow: 0 0 10px rgba(0,0,0, 0.1);
  }
}
