@import "../style/global";

.icon {
  position: relative;
  display: block;

  svg {
    padding: 0;
    @include absolute($top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
  }
}
