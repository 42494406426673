@import '../../style/theme.scss';

@keyframes pulsate-dot {
  0% { opacity: 0; transform: scale(.5); }
  50% { opacity: 1;  transform: scale(1); }
  100% { opacity: 0; transform: scale(.5); }
}

.loading--dots {
  position: absolute;
  display: block;
  left: 50%;
  transform: translateX(-50%);

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 1px 1px 0 0;
    border-radius: 10px;
    background:$themeColor;
    opacity: 0;
    animation: pulsate-dot 1.2s infinite ease-in-out;
  }

  span:nth-child(1) {
    animation-delay: 300ms;
  }
  span:nth-child(2) {
    animation-delay: 150ms;
  }
  span:nth-child(3) {
    animation-delay: 200ms;
  }
}