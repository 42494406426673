// internal variables

$white-base: #ffffff;
$black-base: #000000;

$bgColor: #f7f7f7;
$mapColor: #e2f4ff;

$themeColor: #000667;
$themeColor2: #a9e5fb;
$themeColor3: #ff451f;
$themeColor4: #faf7f4;
$accentColor: #e9510e;
$tableBackground: rgba(206, 215, 224, 100);

// global variables

$modalBackgroundColor: $themeColor4;

$mapButtonBackground: transparentize($themeColor, 0.2);
$mapButtonHoverBackground: darken($themeColor, 10%);

$color-primary: $themeColor3;

$color-text-light: $white-base;
$color-text-dark: $black-base;

$overlay: transparentize(
  $color: $themeColor,
  $amount: 0.5,
);
$shadow: rgba(0, 0, 0, 0.1);
