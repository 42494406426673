@import "../style/theme.scss";

.buttons {
  @include fixed($top: 265px, $left: 10px);
  @include square(40px);
  border-radius: 0;
  margin: 1px;

  .article-view--tip-btn {
    @include square(100%);
    border: 1px solid rgba(0, 46, 76, 0.8);
    background: $mapButtonBackground;
    fill: $white-base;

    &:hover {
      background: $mapButtonHoverBackground;
    }

    .icon {
      margin: 0 auto;
      svg {
        @include square(18px);
      }
    }
  }
}
