@import "../style/theme.scss";

.checkbox--container {
  @include size(25px, 25px);
  display: inline-block;
  border: 1px solid $black-base;
  padding-top: 2px;
  padding-left: 0px;
  border-radius: 1px;
  vertical-align: middle;

  .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 18px;
    > svg {
      height: 18px;
    }
  }
}
