@import "../style/theme.scss";

.table--root {
  margin-bottom: 20px;
  min-height: 120px;

  @media (max-width: 1200px) {
    min-height: 80px;
  }

  .table--flex-grid {
    display: table;
    width: 100%;

    @media (max-width: 1200px) {
      display: block;
    }

    .table--flex-row {
      //@at-root display: table-row;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 3fr 1fr 2fr 2fr 2fr;
      //width: 40%;
      // margin: 0px 0;
      cursor: pointer;

      @media (max-width: 1200px) {
        flex-direction: column;
        position: relative;
        display: flex;
        margin: 0;
      }

      .table--flex-col {
        // display: table-cell;
        padding: 5px 15px 5px 5px;
        align-self: center;
        @media (max-width: 1200px) {
          position: relative;

          &::before {
            width: 100px;
            content: attr(data-header);
            margin-right: 5px;
            padding: 5px;
            font-size: 14px;
            opacity: 0.8;
            display: inline-block;
            border-right: 1px solid #0006;
          }
        }
      }

      .table--flex-col.zoom {
        z-index: 1000;
        @media (max-width: 1200px) {
          position: absolute;
          right: 0;
          padding: 2px 2px;
        }

        button {
          z-index: 1000;
          padding: 6px;
          border: 1px outset #ccc;
          border-radius: 10%;
          background: rgba(255, 255, 255, 0.9);
          margin: 0;
          svg {
            height: 14px;
            width: 14px;
          }
        }

        &:before {
          padding: 0;
          margin-right: 0;
        }
      }

      .table--show-all {
        display: none;
        @media (max-width: 768px) {
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          border: 1px outset #ccc;
          border-radius: 10%;
          background: rgba(255, 255, 255, 0.9);
          margin: 2px;
          padding: 2px 2px;

          .icon {
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    .table--flex-row.header {
      background: $themeColor2;
      color: $themeColor;
      width: 100%;
      border: none;
      padding: 5px 0px;

      @media (max-width: 1200px) {
        padding: 5px 0;
      }

      .table--flex-col {
        padding: 10px 5px;
      }
    }
    .table--flex-row.selected {
      background: $themeColor3;
    }
  }
  .table--flex-row:nth-child(odd) {
    background: transparentize($themeColor2, 0.6);
  }

  &.cat2 {
    .table--flex-grid {
      .table--flex-row.header {
        // background: transparentize($themeColor2, 0);
      }
    }
  }
  &.cat3 {
    .table--flex-grid {
      .table--flex-row.header {
        // background: transparentize($themeColor2, 0.5);
      }
    }
  }

  .loading--dots {
    margin-top: 15px;
  }
}
