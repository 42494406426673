@import "../style/global";

div.react-openlayers--map {
  top: 78px;
  right: 60%;
  position: fixed;

  @media (max-width: 1024px) {
    right: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
    right: 0;
    height: calc(60vh - 78px);
  }
}

.map-layout--root {
  .map-layout--components {
    position: absolute;
    padding: 15px;
    right: 0;
    left: 40%;
    top: 78px;
    min-height: calc(100vh - 78px);
    background: $white-base;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
      left: 50%;
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 60vh;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      min-height: auto;
    }
  }
}
