@import "./colors";

@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@keyframes rotationAnim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin rotate($speed: 1.5s, $direction: normal) {
  animation: rotationAnim $speed linear infinite $direction;
}

@mixin contentBox($lgWidth: 440px, $mdWidth: 400px) {
  background-color: $themeColor3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: $lgWidth;

  @media (max-width: 1260px) {
    width: $mdWidth;
  }

  @media (max-width: 768px) {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
    top: 60vh;
    left: 0;
    right: 0;
    width: auto;
    margin-bottom: 0;
    min-height: 60vh;
  }
}

@mixin mapButton() {
  border-radius: 0;
  @include square(40px);

  background: $mapButtonBackground;
  border: 1px solid $mapButtonHoverBackground;
  &:hover {
    background: $mapButtonHoverBackground;
  }
}
